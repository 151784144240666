<template>
  <v-form @submit.prevent="submitResponse">
    <p>
      Back on planet Earth, elements have different isotopic distributions. The atom {{ atomName }}
      (
      <chemical-isotope-value :value="chemicalElement" />
      ) has two common isotopes.
      <chemical-isotope-value :value="isotope1" />
      has an isotopic mass of
      <latex-number :number="mass1" />
      while
      <chemical-isotope-value :value="isotope2" />
      has an isotopic mass of
      <latex-number :number="mass2" />
      . Based on the atomic weight of {{ atomName }}
      (
      <latex-number :number="atomicMass" />
      ), determine the percentage of each isotope on Earth.
    </p>
    <calculation-input
      v-model="inputs.input1"
      :prepend-text="'$' + isotope1CE + ':$'"
      append-text="$\%$"
      :disabled="!allowEditing"
    />
    <br />
    <calculation-input
      v-model="inputs.input2"
      :prepend-text="'$' + isotope2CE + ':$'"
      append-text="$\%$"
      :disabled="!allowEditing"
    />
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalIsotopeValue from '../ChemicalIsotopeValue';
import CalculationInput from '../inputs/CalculationInput';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'Question6',
  components: {
    LatexNumber,
    CalculationInput,
    ChemicalIsotopeValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
  computed: {
    chemicalElement() {
      console.log(this.taskState.getValueBySymbol('element').content);
      return this.taskState.getValueBySymbol('element').content;
    },
    atomName() {
      return this.chemicalElement.name;
    },
    atomicMass() {
      return this.chemicalElement.mass;
    },
    isotopes() {
      return this.chemicalElement.isotopes;
    },
    isotope1() {
      return this.isotopes[0];
    },
    abundance1() {
      return this.isotope1.abundance;
    },
    mass1() {
      return this.isotope1.mass;
    },
    isotope2() {
      return this.isotopes[1];
    },
    abundance2() {
      return this.isotope2.abundance;
    },
    mass2() {
      return this.isotope2.mass;
    },
    isotope1CE() {
      return `\\ce{^{${this.isotope1.massNumber}}${this.isotope1.symbol}}`;
    },
    isotope2CE() {
      return `\\ce{^{${this.isotope2.massNumber}}${this.isotope2.symbol}}`;
    },
  },
};
</script>
